import type { Dispatch, Commit } from 'vuex';
import { NAMESPACE, ActionTypes, MutationTypes, PixcapArticle, StrapiPaginationMeta, PixcapBlogCategory } from '@/models/store/blog.interface';

export const actionsWrapper = {
	getArticleBySlug({ dispatch }: { dispatch: Dispatch }, payload: { slug: string }): Promise<PixcapArticle> {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_ARTICLE_BY_SLUG}`, payload, { root: true });
	},

	getArticles(
		{ dispatch }: { dispatch: Dispatch },
		payload: { page?: number; pageSize?: number; overrideBlogLocale?: string }
	): Promise<[articles: PixcapArticle[], pagination: StrapiPaginationMeta]> {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_ARTICLES}`, payload, { root: true });
	},

	getRelatedArticles(
		{ dispatch }: { dispatch: Dispatch },
		payload: { page?: number; pageSize?: number; articleSlug: string; categorySlug: string }
	): Promise<[articles: PixcapArticle[], pagination: StrapiPaginationMeta]> {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_RELATED_ARTICLES}`, payload, { root: true });
	},

	getArticleByCategory(
		{ dispatch }: { dispatch: Dispatch },
		payload: { categorySlug: string; page?: number; pageSize?: number }
	): Promise<[articles: PixcapArticle[], pagination: StrapiPaginationMeta]> {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_ARTICLES_BY_CATEGORY}`, payload, { root: true });
	},

	getCategoryBySlug({ dispatch }: { dispatch: Dispatch }, payload: { categorySlug: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_CATEGORY_BY_SLUG}`, payload, { root: true });
	},
	getCategories({ dispatch }: { dispatch: Dispatch }): Promise<PixcapBlogCategory[] | []> {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_CATEGORIES}`, {}, { root: true });
	},
};
export const mutationsWrapper = {
	setBlogLocale({ commit }: { commit: Commit }, locale: string | null) {
		commit(`${NAMESPACE}/${MutationTypes.SET_BLOG_LOCALE}`, locale, { root: true });
	},
};
