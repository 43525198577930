import { HttpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';

export default (context, inject) => {
	const { store } = context;

	let axiosConfig = {};
	if (process.server)
		axiosConfig = {
			cookie: context.req.headers.cookie,
			'Content-Type': 'application/json',
		};
	const httpClient = new HttpClient(
		{
			isClient: process.client,
			baseURL: process.env.ROOT_URL,
		},
		store,
		axiosConfig
	);
	store.$httpClient = httpClient;
	inject('httpClient', httpClient);
};
