import { SORTING_RENDERERS_OPTIONS } from '@pixcap/ui-core/constants/community.constants';
import { ICommunityState } from '@/models/store/community.interface';
import { COMMUNITY_TABS } from '@/constants/community.constants';

export const COMMUNITY_SLIDER_ITEMS_DEFAULT_STATE: ICommunityState['communityItemsSlider'] = {
	totalPages: 1,
	totalItems: 1,
	page: 0,
	size: 10,
	content: [],
};

export const COMMUNITY_ITEMS_SORTBY_DEFAULT_STATE = SORTING_RENDERERS_OPTIONS.featured;

export const getDefaultState = function (): ICommunityState {
	return {
		communityItems: getDefaultCommunityState(100),
		isFetchingCommunityItems: false,

		communityItemsSlider: COMMUNITY_SLIDER_ITEMS_DEFAULT_STATE,
		isFetchingCommunitySlider: false,

		communityItem: null,
		isFetchingCommunityItemDetails: false,

		communityRelatedItemList: getDefaultCommunityState(20),
		isFetchingCommunityRelatedModels: false,

		communityItemInteracting: null,
		isInteractingCommunityItem: false,

		selectedCommunityItemSlug: null,

		communityItemsSortBy: COMMUNITY_ITEMS_SORTBY_DEFAULT_STATE,

		activeCommunityTab: COMMUNITY_TABS[0],
	};
};

export default (): ICommunityState => getDefaultState();

export function getDefaultCommunityState(pageSize: number) {
	return {
		listOfItems: [],
		currentPage: 0,
		totalItems: 0,
		totalPages: null,
		pageSize,
	};
}
