import { RouteConfig } from 'vue-router';
import { redirectToRoute, BasePermanentRouteComponent } from '@/router/utils';

export default [
	{
		path: '/contributors',
		name: 'Contributors',
		component: BasePermanentRouteComponent,
		beforeEnter: redirectToRoute,
	},
	{
		path: '/tutorials',
		name: 'TutorialsDashboard',
		component: BasePermanentRouteComponent,
		beforeEnter: redirectToRoute,
	},
	{
		path: '/cms/submissions',
		name: 'CMSSubmissions',
		component: BasePermanentRouteComponent,
		beforeEnter: redirectToRoute,
	},
	{
		path: `/design/create`,
		name: 'DesignCreateMiddleware',
		component: BasePermanentRouteComponent,
		beforeEnter: redirectToRoute,
	},
	{
		path: `/design/:projectUUID`,
		name: 'CustomizerEditor',
		component: BasePermanentRouteComponent,
		beforeEnter: redirectToRoute,
	},
	{
		path: '/editor/:projectUUID',
		name: 'Editor',
		component: BasePermanentRouteComponent,
		beforeEnter: redirectToRoute,
	},
	{
		path: '/auth/',
		children: [
			{
				path: 'integrate',
				name: 'AccountIntegration',
				component: BasePermanentRouteComponent,
				beforeEnter: redirectToRoute,
			},
		],
	},
] as RouteConfig[];
