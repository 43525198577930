import Vue from 'vue';
// import { setNotifyFnc } from '@pixcap/ui-core/helpers/notification';
// import Notifications from 'vue-notification/dist/ssr';
export default async (context, inject) => {
	const Notifications = (await import('vue-notification/dist/ssr')).default;
	const { setNotifyFnc } = await import('@pixcap/ui-core/helpers/notification');
	Vue.use(Notifications);
	context.$notify = Vue.notify;
	setNotifyFnc(Vue.notify);
	inject('notify', Vue.notify);
};
