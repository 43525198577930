import logger from '@pixcap/ui-core/helpers/logger';
import { appUtilities } from '@/modules/appUtilities';

export default (context, inject) => {
	appUtilities.$crisp = function (event, token, email) {
		logger.log(` cript event ${event} => ${token}:${email}`);
		if (event == 'login') {
			// @ts-ignore
			window.CRISP_TOKEN_ID = token;
			// @ts-ignore
			if (window.$crisp) window.$crisp.push(['set', 'user:email', [email]]);
		}

		else if (event == 'logout') {
			// @ts-ignore
			delete window.CRISP_TOKEN_ID;
			// @ts-ignore
			delete window.CRISP_COOKIE_EXPIRE;
		}
		// @ts-ignore
		if (window.$crisp) window.$crisp.push(['do', 'session:reset']);
	};
};
