import { MutationTypes, IContributorState } from '@/models/store/contributor.interface';

export default {
	[MutationTypes.SET_CONTRIBUTOR_INFO](state: IContributorState, contributorInfo) {
		state.contributorInfo = contributorInfo;
	},
	[MutationTypes.SET_IS_FETCHING_CONTRIBUTOR_INFO](state: IContributorState, isFetching: boolean) {
		state.isFetchingContributorInfo = isFetching;
	},
};
