import { MutationTypes, ICommunityState, CommunityItemPayload, TargetDataKey } from '@/models/store/community.interface';
import { getDefaultCommunityState } from '@/store/community/state';

export default {
	[MutationTypes.SET_IS_FETCHING_COMMUNITY_ITEMS]: (state: ICommunityState, isFetching: ICommunityState['isFetchingCommunityItems']) => {
		state.isFetchingCommunityItems = isFetching;
	},
	[MutationTypes.SET_COMMUNITY_ITEMS]: (state: ICommunityState, payload: ICommunityState['communityItems']) => {
		const { currentPage, totalPages, listOfItems, totalItems, pageSize } = payload;
		state.communityItems = {
			pageSize,
			currentPage,
			totalPages,
			totalItems,
			listOfItems: [...state.communityItems.listOfItems, ...listOfItems],
		};
	},
	[MutationTypes.RESET_COMMUNITY_ITEMS]: (state: ICommunityState) => {
		state.communityItems = getDefaultCommunityState(20);
	},

	[MutationTypes.SET_COMMUNITY_RELATED_ITEM_LIST]: (state: ICommunityState, payload: ICommunityState['communityRelatedItemList']) => {
		const { currentPage, totalPages, listOfItems, totalItems, pageSize } = payload;
		state.communityRelatedItemList = {
			pageSize,
			currentPage,
			totalPages,
			totalItems,
			listOfItems: [...state.communityRelatedItemList.listOfItems, ...listOfItems],
		};
	},
	[MutationTypes.SET_IS_FETCHING_COMMUNITY_RELATED_MODELS]: (
		state: ICommunityState,
		isFetching: ICommunityState['isFetchingCommunityRelatedModels']
	) => {
		state.isFetchingCommunityRelatedModels = isFetching;
	},
	[MutationTypes.RESET_COMMUNITY_RELATED_ITEM_LIST]: (state: ICommunityState) => {
		state.communityRelatedItemList = getDefaultCommunityState(20);
	},

	[MutationTypes.SET_COMMUNITY_ITEM]: (state: ICommunityState, item: ICommunityState['communityItem']) => {
		state.communityItem = item;
	},
	[MutationTypes.SET_IS_FETCHING_COMMUNITY_ITEM_DETAILS]: (state: ICommunityState, isFetching: ICommunityState['isFetchingCommunityItemDetails']) => {
		state.isFetchingCommunityItemDetails = isFetching;
	},

	[MutationTypes.SET_COMMUNITY_SLIDER]: (state: ICommunityState, payload: ICommunityState['communityItemsSlider']) => {
		state.communityItemsSlider = payload;
	},
	[MutationTypes.SET_COMMUNITY_SLIDER_LOADING]: (state: ICommunityState, isFetching: ICommunityState['isFetchingCommunitySlider']) => {
		state.isFetchingCommunitySlider = isFetching;
	},

	[MutationTypes.UPDATE_COMMUNITY_ITEMS_BY_INTERACTING]: (state, payload: CommunityItemPayload) => {
		const { jobId, targetDataKey, isLiked } = payload;
		if (targetDataKey === TargetDataKey.communityItem) {
			state[targetDataKey].likes = isLiked ? state[targetDataKey].likes + 1 : state[targetDataKey].likes - 1;
			state[targetDataKey].liked = isLiked;
		} else {
			let index = 0;
			const length = state[targetDataKey].listOfItems.length;
			for (index; index < length; index++) {
				const item = state[targetDataKey].listOfItems[index];
				if (item.jobId === jobId) {
					item.likes = isLiked ? item.likes + 1 : item.likes - 1;
					item.liked = isLiked;
					break;
				}
			}
		}
	},
	[MutationTypes.SET_COMMUNITY_ITEM_INTERACTING_LOADING]: (state: ICommunityState, payload: ICommunityState['isInteractingCommunityItem']) => {
		state.isInteractingCommunityItem = payload;
	},

	[MutationTypes.SET_COMMUNITY_SORT_BY]: (state: ICommunityState, sortBy: ICommunityState['communityItemsSortBy']) => {
		state.communityItemsSortBy = sortBy;
	},

	[MutationTypes.SET_SELECTED_COMMUNITY_ITEM_SLUG](state: ICommunityState, selectedCommunityItemSlug) {
		state.selectedCommunityItemSlug = selectedCommunityItemSlug;
	},

	[MutationTypes.SET_ACTIVE_COMMUNITY_TAB](state: ICommunityState, activeCommunityTab) {
		state.activeCommunityTab = activeCommunityTab;
	},
};
