import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { DEFAULT_APP_LOCALE } from '@/constants/app.constants';
import blogMessages from '@/lang/blog';
import { DEFAULT_BLOG_LOCALE } from '@/constants/blog.constants';
import messages from '@/lang';

export default (context, inject) => {
	Vue.use(VueI18n);
	const i18n = new VueI18n({
		locale: DEFAULT_APP_LOCALE,
		fallbackLocale: DEFAULT_APP_LOCALE,
		messages,
	});

	const { app, route } = context;
	i18n.locale = route.params.locale || DEFAULT_APP_LOCALE;
	app.i18n = context.i18n = i18n;

	const isBlogPage = route.meta.some((meta) => meta.isBlogRoute);
	if (isBlogPage) {
		let blogTranslation = blogMessages[DEFAULT_BLOG_LOCALE];
		if (route.params.locale in blogMessages) {
			blogTranslation = blogMessages[route.params.locale];
		}
		const existingMessages = context.i18n.getLocaleMessage(context.i18n.locale);
		const mergedMessages = { ...existingMessages, ...blogTranslation };
		context.i18n.setLocaleMessage(context.i18n.locale, mergedMessages);
	}
};
