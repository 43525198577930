import type { ActionContext } from 'vuex';
import { API_PATHS } from '@pixcap/ui-core/constants/api.constants';
import { safePromiseExecution } from '@pixcap/ui-core/utils/PromiseUtils';
import logger from '@pixcap/ui-core/helpers/logger';
import {
	CommunityItem,
	CommunityItemPayload,
	CommunityResponse,
	CommunitySliderPayload,
	ActionTypes,
	CommunitySortBy,
} from '@/models/store/community.interface';
import { mutationsWrapper } from '@/store/community/wrapper';
import { createSearchParams } from '@/utils/store';
import { HttpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';

const ASSETMANAGER_PATH = API_PATHS.ASSETMANAGER_PATH;

export default {
	async [ActionTypes.GET_LIST_COMMUNITY_ITEMS](
		context: ActionContext<any, any>,
		payload: {
			sortBy: CommunitySortBy;
			refresh?: boolean;
			page?: number;
			pageSize?: number;
			search?: string;
			isFetchRelated?: boolean;
			exportType?: string;
			ignoreIds?: string[];
		}
	) {
		const { page, pageSize, sortBy, refresh, search, exportType, isFetchRelated, ignoreIds } = payload;
		const currentPage = refresh ? 0 : page;
		if (refresh) {
			if (search || isFetchRelated) {
				mutationsWrapper.resetCommunityRelatedItemList(context);
			} else {
				mutationsWrapper.resetCommunityItems(context);
			}
		}

		if (search || isFetchRelated) {
			mutationsWrapper.setIsFetchingCommunityRelatedModels(context, true);
		} else {
			mutationsWrapper.setIsFetchingCommunityItems(context, true);
		}

		const params = {
			page: currentPage,
			pageSize,
		};

		search && Object.assign(params, { search });

		ignoreIds?.length && Object.assign(params, { ignoreIds });
		sortBy && Object.assign(params, { sortBy });
		exportType && Object.assign(params, { exportType });
		const promise = (this.$httpClient as HttpClient).get(`${ASSETMANAGER_PATH}/export/renderer/community/search`, {
			params: createSearchParams(params),
		});
		const [err, resp] = await safePromiseExecution(promise);

		if (search || isFetchRelated) {
			mutationsWrapper.setIsFetchingCommunityRelatedModels(context, false);
		} else {
			mutationsWrapper.setIsFetchingCommunityItems(context, false);
		}

		if (err) {
			logger.log(`Error while getting community renderers`);
			return;
		}
		if (!resp.data) return;

		const { content, totalItems, totalPages }: CommunityResponse = resp.data;
		if (search || isFetchRelated) {
			mutationsWrapper.updateCommunityRelatedModels(context, {
				listOfItems: content,
				pageSize,
				currentPage: content.length ? currentPage + 1 : currentPage,
				totalItems,
				totalPages,
			});
		} else {
			mutationsWrapper.updateCommunityItems(context, {
				listOfItems: content,
				pageSize,
				currentPage: content.length ? currentPage + 1 : currentPage,
				totalItems,
				totalPages,
			});
		}
	},

	async [ActionTypes.GET_COMMUNITY_ITEM](context: ActionContext<any, any>, slug: string) {
		mutationsWrapper.setIsFetchingCommunityItemDetails(context, true);
		mutationsWrapper.setCommunityItem(context, null);

		const promise = (this.$httpClient as HttpClient).get(`${ASSETMANAGER_PATH}/export/renderer/community/${slug}`);
		const [err, resp] = await safePromiseExecution(promise);
		mutationsWrapper.setIsFetchingCommunityItemDetails(context, false);
		if (err) {
			logger.log(`Error while getting community renderer: ${slug}`);
			return;
		}
		mutationsWrapper.setCommunityItem(context, resp.data as CommunityItem);
	},

	async [ActionTypes.INTERACTING_COMMUNITY_ITEM](context: ActionContext<any, any>, payload: CommunityItemPayload) {
		const { jobId, targetDataKey, isLiked } = payload;
		mutationsWrapper.setCommunityItemInteractingLoading(context, true);

		const promise = (this.$httpClient as HttpClient).put(`${ASSETMANAGER_PATH}/export/renderer/community/${jobId}/like`, { like: isLiked });
		const [err, resp] = await safePromiseExecution(promise);

		mutationsWrapper.setCommunityItemInteractingLoading(context, false);

		if (err) {
			logger.log('Interacting failed!');
		}

		if (!resp.data) return;

		mutationsWrapper.updateCommunityItemsByInteracting(context, { jobId, targetDataKey, isLiked });
	},

	async [ActionTypes.GET_LIST_COMMUNITY_ITEMS_SLIDER](context: ActionContext<any, any>, payload: CommunitySliderPayload) {
		mutationsWrapper.setCommunityItemsSliderLoading(context, true);

		const { pageSize } = payload;
		const promise = (this.$httpClient as HttpClient).get(`${ASSETMANAGER_PATH}/export/renderer/community/slides/?pageSize=${pageSize}`);

		mutationsWrapper.setCommunityItemsSliderLoading(context, false);

		const [err, resp] = await safePromiseExecution(promise);

		if (err) {
			logger.log('Error while getting slider');
			return;
		}
		mutationsWrapper.setCommunityItemsSlider(context, resp.data || []);
	},
};
