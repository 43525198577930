import { WindowEventsManager } from '@pixcap/ui-core/widgets/WindowEventsManager';
import { ServiceWorkerManager } from '@pixcap/ui-core/services/ServiceWorkerManager';
import { socketManagerFactory } from '@pixcap/ui-core/services/socketmanager';
import { appUtilities } from '@/modules/appUtilities';
import ServerRenderManager from '@pixcap/ui-core/services/ServerRenderManager';

export default (context, inject) => {
	appUtilities.$widgets.windowEventsManager = new WindowEventsManager();
	appUtilities.$services.serviceWorkerManager = new ServiceWorkerManager(context.app.router, context.app.store);
	appUtilities.$services.idleRenderManager = new ServerRenderManager(context.app.store);
	socketManagerFactory(context.store);
};
