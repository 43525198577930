import type { Dispatch, Commit } from 'vuex';
import {
	NAMESPACE,
	ActionTypes,
	MutationTypes,
	CommunitySortBy,
	CommunityItemPayload,
	CommunityPayload,
	CommunitySliderPayload,
	ICommunityState,
} from '@/models/store/community.interface';

export const actionsWrapper = {
	getListCommunityItems({ dispatch }: { dispatch: Dispatch }, payload: CommunityPayload) {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_LIST_COMMUNITY_ITEMS}`, payload, { root: true });
	},
	getCommunityItem({ dispatch }: { dispatch: Dispatch }, slug: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_COMMUNITY_ITEM}`, slug, { root: true });
	},
	interactingCommunityItem({ dispatch }: { dispatch: Dispatch }, payload: CommunityItemPayload) {
		return dispatch(`${NAMESPACE}/${ActionTypes.INTERACTING_COMMUNITY_ITEM}`, payload, { root: true });
	},
	getListCommunityItemsSlider({ dispatch }: { dispatch: Dispatch }, payload: CommunitySliderPayload) {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_LIST_COMMUNITY_ITEMS_SLIDER}`, payload, { root: true });
	},
};
export const mutationsWrapper = {
	setIsFetchingCommunityItems({ commit }: { commit: Commit }, isFetching: ICommunityState['isFetchingCommunityItems']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_COMMUNITY_ITEMS}`, isFetching, { root: true });
	},
	updateCommunityItems({ commit }: { commit: Commit }, items: ICommunityState['communityItems']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_COMMUNITY_ITEMS}`, items, { root: true });
	},
	resetCommunityItems({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_COMMUNITY_ITEMS}`, undefined, { root: true });
	},

	setIsFetchingCommunityItemDetails({ commit }: { commit: Commit }, isFetching: ICommunityState['isFetchingCommunityItemDetails']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_COMMUNITY_ITEM_DETAILS}`, isFetching, { root: true });
	},
	setCommunityItem({ commit }: { commit: Commit }, item: ICommunityState['communityItem']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_COMMUNITY_ITEM}`, item, { root: true });
	},

	setIsFetchingCommunityRelatedModels({ commit }: { commit: Commit }, isFetching: ICommunityState['isFetchingCommunityRelatedModels']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_COMMUNITY_RELATED_MODELS}`, isFetching, { root: true });
	},
	updateCommunityRelatedModels({ commit }: { commit: Commit }, items: ICommunityState['communityRelatedItemList']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_COMMUNITY_RELATED_ITEM_LIST}`, items, { root: true });
	},
	resetCommunityRelatedItemList({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_COMMUNITY_RELATED_ITEM_LIST}`, undefined, { root: true });
	},

	setCommunityItemsSliderLoading({ commit }: { commit: Commit }, loading: ICommunityState['isFetchingCommunitySlider']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_COMMUNITY_SLIDER_LOADING}`, loading, { root: true });
	},
	setCommunityItemsSlider({ commit }: { commit: Commit }, items: ICommunityState['communityItemsSlider']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_COMMUNITY_SLIDER}`, items, { root: true });
	},

	setCommunityItemInteractingLoading({ commit }: { commit: Commit }, loading: ICommunityState['isInteractingCommunityItem']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_COMMUNITY_ITEM_INTERACTING_LOADING}`, loading, { root: true });
	},
	updateCommunityItemsByInteracting({ commit }: { commit: Commit }, payload: CommunityItemPayload) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_COMMUNITY_ITEMS_BY_INTERACTING}`, payload, { root: true });
	},

	setSelectedCommunityItemSlug({ commit }: { commit: Commit }, selectedCommunityItemSlug: string) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SELECTED_COMMUNITY_ITEM_SLUG}`, selectedCommunityItemSlug);
	},

	setCommunityItemsSortBy({ commit }: { commit: Commit }, sortBy: CommunitySortBy) {
		commit(`${NAMESPACE}/${MutationTypes.SET_COMMUNITY_SORT_BY}`, sortBy, { root: true });
	},

	setCommunityActiveTab({ commit }: { commit: Commit }, activeTab: string) {
		commit(`${NAMESPACE}/${MutationTypes.SET_ACTIVE_COMMUNITY_TAB}`, activeTab, { root: true });
	},
};
