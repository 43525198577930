import { Component } from 'vue-property-decorator';
Component.registerHooks([
	'beforeRouteEnter',
	'beforeRouteUpdate',
	'beforeRouteLeave',
	'asyncData',
	'fetch',
	'fetchOnServer',
	'head',
	'key',
	'layout',
	'loading',
	'middleware',
	'scrollToTop',
	'transition',
	'validate',
	'watchQuery',
	'meta',
]);

export default (app) => {}