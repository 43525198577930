import logger from '@pixcap/ui-core/helpers/logger';
import { appUtilities } from '@/modules/appUtilities';

export default (context, inject) => {
	appUtilities.$GTM_dataLayer = function (event, email) {
		logger.log(` gtm event ${event} => ${email}`);
		// @ts-ignore
		if (window.dataLayer) window.dataLayer.push({
			'event': event,
			'email': email
		});
	};
};
