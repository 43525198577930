import Vue from 'vue';
import { DEFAULT_APP_LOCALE } from '@/constants/app.constants';
// import VueTimeago from 'vue-timeago';

export default async (context, inject) => {
	const VueTimeago = (await import('vue-timeago')).default;
	const locale = context.route.params.locale || DEFAULT_APP_LOCALE;
	Vue.use(VueTimeago, {
		locale,
		locales: {
			de: require('date-fns/locale/de'),
			es: require('date-fns/locale/es'),
			br: require('date-fns/locale/pt'),
			kr: require('date-fns/locale/ko'),
			jp: require('date-fns/locale/ja'),
		},
	});
};
