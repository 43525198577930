import { appUtilities } from '@/modules/appUtilities';
export default (context, inject) => {
	appUtilities.$captureError = (err: any) => {
		context.$sentry?.captureException(err);
	};
	appUtilities.$setUserForErrorTracker = (userId: string, email: string) => {
		context.$sentry?.setUser({
			id: userId,
			email,
		});
	};
};
