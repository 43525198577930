import Vue from 'vue';
// import PortalVue from 'portal-vue';
// import vClickOutside from 'v-click-outside';
// import VTooltip from 'v-tooltip';
// import Vue2TouchEvents from 'vue2-touch-events';

const directivePortalVue = async () => {
	const PortalVue = (await import('portal-vue')).default;
	Vue.use(PortalVue);
};

const directiveVTooltip = async () => {
	const VTooltip = (await import('v-tooltip')).default;
	Vue.use(VTooltip);
};

const directiveVue2TouchEvents = async () => {
	const Vue2TouchEvents = (await import('vue2-touch-events')).default;
	Vue.use(Vue2TouchEvents);
};

const directiveVClickOutside = async () => {
	const vClickOutside = (await import('v-click-outside')).default;
	Vue.use(vClickOutside);
};

export default (context, inject) => {
	directivePortalVue();
	directiveVTooltip();
	directiveVue2TouchEvents();
	directiveVClickOutside();
};
