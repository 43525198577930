import Vue from 'vue';
import logger from '@pixcap/ui-core/helpers/logger';
import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';

export default (context) => {
	const { app } = context;
	Vue.config.errorHandler = function (err) {
		logger.warn('Error captured by vue errorHandler');
		logger.error(err);
		Vue.notify({
			type: NotificationType.ERROR,
			title: app.i18n ? app.i18n.t('toast.error') : 'Something went wrong. Please refresh and try again.',
		});
	};
};
