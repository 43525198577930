import { getUserDeviceType } from '@pixcap/ui-core/utils/PlatformUtils';

export default (context, inject) => {
	let userAgent;
	if (process.server) {
		userAgent = context.req.headers['user-agent'] || '';
	} else {
		userAgent = window.navigator.userAgent;
	}
	context.$pixcap.$deviceType = getUserDeviceType(userAgent);
};
