import type { Dispatch, Commit } from 'vuex';
import { NAMESPACE, MutationTypes, ActionTypes, IContributorState } from '@/models/store/contributor.interface';

export const mutationsWrapper = {
	setContributorInfo({ commit }: { commit: Commit }, contributorInfo: IContributorState['contributorInfo']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_CONTRIBUTOR_INFO}`, contributorInfo, { root: true });
	},
	setIsFetchingContributorInfo({ commit }: { commit: Commit }, isFetching: IContributorState['isFetchingContributorInfo']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_CONTRIBUTOR_INFO}`, isFetching, { root: true });
	},
};

export const actionsWrapper = {
	getContributorInfo({ dispatch }: { dispatch: Dispatch }, contributorSlug: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_CONTRIBUTOR_INFO}`, contributorSlug, { root: true });
	},
};
