import { RouteConfig } from 'vue-router';
import { interopDefault } from '@/utils/promise';
import PixcapBlog from '@/layouts/PixcapBlog.vue';
import { AVAILABLE_BLOG_LOCALE_ROUTE_PARAMS } from '@/constants/blog.constants';

const Article = () => interopDefault(import('@/pages/blog/Article.vue'));
const BlogByCategory = () => interopDefault(import('@/pages/blog/BlogByCategory.vue'));
const Blog = () => interopDefault(import('@/pages/blog/Blog.vue'));

const localeRegex = AVAILABLE_BLOG_LOCALE_ROUTE_PARAMS.join('|');

const blogRoutes: RouteConfig = {
	path: `/:locale(${localeRegex})?/blog`,
	component: PixcapBlog,
	children: [
		{
			path: '',
			name: 'Blog',
			meta: {
				isPublicRoute: true,
				isBlogRoute: true,
			},
			component: Blog,
		},
		{
			path: `category/:categorySlug`,
			name: 'BlogByCategory',
			meta: {
				isPublicRoute: true,
				isBlogRoute: true,
			},
			component: BlogByCategory,
		},
		{
			path: `:articleSlug`,
			name: 'Article',
			meta: {
				isPublicRoute: true,
				isBlogRoute: true,
			},
			component: Article,
		},
	],
};

export default blogRoutes;
